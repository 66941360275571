<template>
  <div class="popup">
    <div class="popup__block">
      <div class="staking-popup">
        <div class="staking-popup__full">
          <div class="staking-popup__section">
            <div class="staking-popup__section-header">
              <div class="popup__title">WELD {{ $t("staking") }}</div>
              <popup-close-button @click="closePopup"></popup-close-button>
            </div>
            <div class="staking-popup__section-body">
              <base-loader v-if="initialDataLoading"></base-loader>
              <div class="staking-popup__amount-info">
                <div class="staking-popup__amount-info-item">
                  <div class="staking-popup__amount-info-item-title">
                    {{ $t("minimum_weld_for_staking") }}:
                  </div>
                  <div class="staking-popup__amount-info-item-value">
                    {{ toFixedDecimals(minimumNeedleWeldAmount, 2) }}
                  </div>
                </div>
                <div class="staking-popup__amount-info-item">
                  <div class="staking-popup__amount-info-item-title">
                    {{ $t("current_weld_amount") }}:
                  </div>
                  <div class="staking-popup__amount-info-item-value">
                    {{ toFixedDecimals(weldBalance, 2) }}
                  </div>
                </div>
                <div class="staking-popup__amount-info-item">
                  <div class="staking-popup__amount-info-item-title">
                    {{ $t("current_weld_usdt_rate") }}:
                  </div>
                  <div class="staking-popup__amount-info-item-value">
                    {{ toFixedDecimals(this.ratesUSDT.weld, 2) }}
                  </div>
                </div>
              </div>
              <div
                class="staking-popup__buy"
                v-if="
                  (!canStake && canDepositMinimumAmount) ||
                  buyAndStakeProcessActive
                "
              >
                <base-loader v-if="buyAndStakeProcessActive"></base-loader>
                <div class="staking-popup__buy-hint">
                  {{
                    $t("not_enough_weld_for_staking", {
                      weldAmount: toFixedDecimals(
                        insufficientNeedleWeldAmount,
                        2
                      ),
                    })
                  }}
                </div>
                <div class="weld-buy-form">
                  <div class="weld-buy-form__header">
                    <div class="weld-buy-form__setters">
                      <div
                        class="weld-buy-form__setters-item"
                        @click="setMinimumWeld"
                      >
                        {{ $t("set_minimum_required_weld") }}
                      </div>
                    </div>
                    <div
                      class="weld-buy-form__coin-balance"
                      v-if="selectedCoin"
                    >
                      {{ toFixedDecimals(this.coinBalance, 2) }}
                      {{ this.selectedCoin }}
                    </div>
                  </div>

                  <div class="weld-buy-form__input">
                    <text-input
                      :mask="amountWeldMask"
                      v-model="weldAmount"
                      :title="$t('weld_amount')"
                      :v-errors="v$.weldAmount.$errors"
                      class="weld-buy-form__input-el"
                    ></text-input>
                    <div class="weld-buy-form__input-coin">WELD</div>
                  </div>
                  <div
                    class="weld-buy-form__input weld-buy-form__input--with-coin"
                  >
                    <text-input
                      :mask="amountCoinMask"
                      v-model="coinAmount"
                      :v-errors="v$.coinAmount.$errors"
                      :title="$t('coin_amount')"
                      class="weld-buy-form__input-el"
                    ></text-input>
                    <custom-select
                      :values="formattedAvailableCoins"
                      :show-selected-title="true"
                      :empty-placeholder="$t('select_coin')"
                      v-model="selectedCoin"
                      class="weld-buy-form__input-coin-select"
                    ></custom-select>
                  </div>
                  <div class="weld-buy-form__actions">
                    <div class="weld-stake-form__actions-stake">
                      <primary-button
                        :label="$t('buy_and_stake')"
                        :disabled="buyAndStakeProcessActive"
                        @click="buyWeldAndStake"
                      ></primary-button>
                      <div class="weld-stake-form__actions-stake-hint">
                        {{ $t("stake_term") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="staking-popup__stake"
                v-else-if="canDepositMinimumAmount && !buyAndStakeProcessActive"
              >
                <base-loader v-if="stakingProcessActive"></base-loader>
                <div class="weld-stake-form">
                  <div class="weld-stake-form__header">
                    <div class="weld-stake-form__setters">
                      <div
                        class="weld-stake-form__setters-item"
                        @click="setMinimumWeldForStake"
                      >
                        {{ $t("set_minimum_required_weld") }}
                      </div>
                      <div
                        class="weld-stake-form__setters-item"
                        @click="setAllAvailableWeldForStake"
                      >
                        {{ $t("set_maximum_available_weld") }}
                      </div>
                    </div>
                  </div>
                  <div class="weld-buy-form__input">
                    <text-input
                      :mask="amountWeldMask"
                      v-model="weldStakeAmount"
                      :title="$t('weld_amount_to_stake')"
                      :v-errors="v$.weldStakeAmount.$errors"
                      class="weld-buy-form__input-el"
                    ></text-input>
                    <div class="weld-buy-form__input-coin">WELD</div>
                  </div>
                  <div class="weld-stake-form__actions">
                    <div class="weld-stake-form__actions-stake">
                      <primary-button
                        :label="$t('stake')"
                        @click="stakeWeldStart"
                        :disabled="stakingProcessActive"
                      ></primary-button>
                      <div class="weld-stake-form__actions-stake-hint">
                        {{ $t("stake_term") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";
import { PancakeSwapService } from "@/services/crypto/exchanges/pancake-swap-service";
import TextInput from "@/components/input/TextInput";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { Web3Service } from "@/services/crypto/web3/web3-service";
import CustomSelect from "@/components/input/CustomSelect";
import PrimaryButton from "@/components/button/PrimaryButton";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BaseLoader from "@/components/base/BaseLoader";
import Big from "big.js";

import mathMixin from "@/mixins/math-mixin";

const amountWeldMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  decimalLimit: 18,
});

const amountCoinMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  decimalLimit: 18,
});

export default {
  name: "StakingPopup",
  inject: [
    "updateWeldBalance",
    "connectAccounts",
    "validateNetworkAndChangeIfNotValid",
    "showErrorTip",
  ],
  mixins: [mathMixin],
  components: {
    BaseLoader,
    PrimaryButton,
    CustomSelect,
    TextInput,
    PopupCloseButton,
  },
  props: {
    chainId: {
      required: true,
    },
    selectedAccount: {
      required: true,
    },
    weldBalance: {
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      ratesUSDT: {
        weld: 0,
        coin: 0,
      },
      amountWeldMask,
      amountCoinMask,
      weldAmount: 0,
      weldStakeAmount: 0,
      coinAmount: 0,
      coinBalance: 0,
      selectedCoin: "USDT",
      availableCoins: Web3Service.AVAILABLE_COINS,
      rateUpdateInterval: null,
      rateUpdateIntervalLimit: 60000,
      updateSelectedCoinBalanceInterval: null,
      updateSelectedCoinBalanceLimit: 10000,
      minimumAmountInUSDT: 1000,
      shouldUpdateWeldAmount: true,
      shouldUpdateCoinAmount: true,
      vuelidateExternalResults: {
        weldAmount: [],
        coinAmount: [],
        weldStakeAmount: [],
      },
      canDepositMinimumAmount: true,
      maximumWeldDepositAmount: null,
      buyAndStakeProcessActive: false,
      stakingProcessActive: false,
      initialDataLoading: true,
      bigNumberHelper: Big,
    };
  },
  validations() {
    if (!this.canStake || this.buyAndStakeProcessActive) {
      return {
        weldAmount: {
          required: required,
        },
        coinAmount: {
          required: required,
        },
      };
    }

    return {
      weldStakeAmount: {
        required: required,
      },
    };
  },
  async created() {
    this.initialDataLoading = true;
    await this.updateWeldRate();
    this.canDepositMinimumAmount =
      await this.validateAbilityForStakingMinAmount();

    this.initialDataLoading = false;

    if (this.selectedCoin) {
      await this.updateSelectedCoinBalance();

      this.updateSelectedCoinBalanceInterval = setInterval(() => {
        this.updateSelectedCoinBalance();
      }, this.updateSelectedCoinBalanceLimit);
    }

    this.rateUpdateInterval = setInterval(() => {
      this.updateWeldRate();
    }, this.rateUpdateIntervalLimit);
  },
  beforeDestroy() {
    clearInterval(this.rateUpdateInterval);
  },
  watch: {
    weldAmount: function () {
      this.v$.$clearExternalResults();
      if (this.shouldUpdateCoinAmount) {
        this.updateSelectedCoinAmount();
      }
    },
    weldStakeAmount: function () {
      this.v$.$clearExternalResults();
    },
    coinAmount: function () {
      if (this.shouldUpdateWeldAmount) {
        this.updateWeldAmount();
      }
    },
    selectedCoin: function () {
      this.updateSelectedCoinAmount();
      this.updateSelectedCoinBalance();
    },
  },
  computed: {
    minimumNeedleWeldAmount: function () {
      if (this.ratesUSDT.weld === 0) {
        return 0;
      }

      return Big(
        Big(this.minimumAmountInUSDT)
          .div(Big(this.ratesUSDT.weld))
          .toPrecision(18, 1)
      ).toPrecision();
    },
    insufficientNeedleUSDTAmount() {
      return Big(
        Big(this.ratesUSDT.weld)
          .times(Big(this.insufficientNeedleWeldAmount))
          .toPrecision(18, 1)
      ).toPrecision();
    },
    insufficientNeedleWeldAmount: function () {
      return Big(
        Big(this.minimumNeedleWeldAmount)
          .minus(Big(this.weldBalance))
          .toPrecision(18, 1)
      ).toPrecision();
    },
    canStake: function () {
      return (
        Big(this.weldBalance).minus(Big(this.minimumNeedleWeldAmount)) >= 0
      );
    },
    formattedAvailableCoins() {
      let _aCoins = [];

      for (let _aCoin in this.availableCoins) {
        _aCoins.push({
          id: this.availableCoins[_aCoin],
          title: this.availableCoins[_aCoin],
        });
      }
      return _aCoins;
    },
  },
  methods: {
    async updateWeldRate() {
      this.ratesUSDT.weld = await new Web3Service().getWeldCoinRate(
        Web3Service.AVAILABLE_COINS.USDT,
        this.chainId
      );
    },
    async updateWeldAmount() {
      if (parseFloat(this.coinAmount) === parseFloat(0)) {
        this.setWeldAmount(0);
        return;
      }

      //NEW ALGORITHM
      let coinWeldRate = await new Web3Service().getWeldCoinRate(
        this.selectedCoin,
        this.chainId
      );

      this.setWeldAmount(
        Big(
          Big(this.coinAmount).div(coinWeldRate).toPrecision(18, 1)
        ).toPrecision()
      );
      //END NEW ALGORITHM

      // if (this.selectedCoin === this.availableCoins.USDT) {
      //   this.setWeldAmount(
      //     Big(
      //       Big(this.coinAmount)
      //         .div(Big(this.ratesUSDT.weld))
      //         .toPrecision(18, 1)
      //     ).toPrecision()
      //   );
      //   return;
      // }
      //
      // let coinRate = await new PancakeSwapService().getCoinRate(
      //   this.selectedCoin
      // );
      //
      // let coinAmountInUSDT = Big(
      //   Big(this.coinAmount).times(Big(coinRate)).toPrecision(18, 1)
      // ).toPrecision();
      //
      // this.setWeldAmount(
      //   Big(
      //     Big(coinAmountInUSDT).div(Big(this.ratesUSDT.weld).toPrecision(18, 1))
      //   ).toPrecision()
      // );
    },
    async updateSelectedCoinAmount() {
      if (parseFloat(this.weldAmount) === parseFloat(0)) {
        this.setCoinAmount(0);
        return;
      }

      //NEW ALGORITHM
      let coinWeldRate = await new Web3Service().getWeldCoinRate(
        this.selectedCoin,
        this.chainId
      );

      this.setCoinAmount(
        Big(
          Big(this.weldAmount).times(coinWeldRate).toPrecision(18, 1)
        ).toPrecision()
      );
      //END NEW ALGORITHM

      // if (this.selectedCoin === this.availableCoins.USDT) {
      //   this.setCoinAmount(
      //     Big(
      //       Big(this.weldAmount).times(this.ratesUSDT.weld).toPrecision(18, 1)
      //     ).toPrecision()
      //   );
      //   return;
      // }
      //
      // let coinRate = await new PancakeSwapService().getCoinRate(
      //   this.selectedCoin
      // );
      //
      // let weldInUSDTAmount = Big(
      //   Big(this.weldAmount).times(Big(this.ratesUSDT.weld)).toPrecision(18, 1)
      // ).toPrecision();
      //
      // this.setCoinAmount(
      //   Big(
      //     Big(weldInUSDTAmount).div(Big(coinRate)).toPrecision(18, 1)
      //   ).toPrecision()
      // );
    },
    setMinimumWeld() {
      this.weldAmount = this.insufficientNeedleWeldAmount;
    },
    async setMinimumCoin() {
      if (this.selectedCoin === this.availableCoins.USDT) {
        this.coinAmount = this.insufficientNeedleUSDTAmount;
        return;
      }

      //NEW ALGORITHM
      let coinWeldRate = await new Web3Service().getWeldCoinRate(
        this.selectedCoin,
        this.chainId
      );
      this.coinAmount = Big(
        Big(this.insufficientNeedleWeldAmount)
          .times(coinWeldRate)
          .toPrecision(18, 1)
      ).toPrecision();
      //END NEW ALGORITHM

      // let coinRate = await new PancakeSwapService().getCoinRate(
      //   this.selectedCoin
      // );
      //
      // console.log(this.insufficientNeedleUSDTAmount);
      //
      // this.coinAmount = Big(
      //   Big(this.insufficientNeedleUSDTAmount).div(coinRate).toPrecision(18, 1)
      // ).toPrecision();
    },
    setWeldAmount(newWeldAmount) {
      this.shouldUpdateCoinAmount = false;
      this.weldAmount = newWeldAmount;
      this.$nextTick(() => {
        this.shouldUpdateCoinAmount = true;
      });
    },
    setCoinAmount(newCoinAmount) {
      this.shouldUpdateWeldAmount = false;
      this.coinAmount = newCoinAmount;
      this.$nextTick(() => {
        this.shouldUpdateWeldAmount = true;
      });
    },
    async buyWeld() {
      const validationResult = await this.v$.$validate();

      if (!validationResult) {
        return false;
      }

      if (
        parseFloat(this.weldAmount) <
        parseFloat(this.insufficientNeedleWeldAmount)
      ) {
        Object.assign(this.vuelidateExternalResults, {
          weldAmount: [
            `You should buy at least ${this.insufficientNeedleWeldAmount} WELD`,
          ],
        });
        return;
      }

      if (!this.selectedAccount) {
        await this.connectAccounts();
      }

      let buyResult;

      if (this.selectedCoin === this.availableCoins.BNB) {
        buyResult = await new Web3Service().buyWeldWithCoin(
          this.coinAmount,
          this.selectedAccount,
          this.chainId,
          {
            amountUSDT: await this.getCoinAmountInUSDT(this.selectedCoin),
            rateUSDT: this.ratesUSDT.weld,
          }
        );
      } else {
        buyResult = await new Web3Service().buyWeldWithToken(
          this.coinAmount,
          this.selectedAccount,
          this.chainId,
          Web3Service.AVAILABLE_COINS[this.selectedCoin],
          {
            amountUSDT: await this.getCoinAmountInUSDT(this.selectedCoin),
            rateUSDT: this.ratesUSDT.weld,
          }
        );
      }

      await this.updateWeldBalance();

      return buyResult;
    },

    async stakeWeldStart() {
      this.stakingProcessActive = true;
      try {
        await this.stakeWeld();
        this.stakingProcessActive = false;

        this.$emit("stakingSuccess", {
          stakedAmount: this.weldStakeAmount,
        });
      } catch (e) {
        console.dir(e);
        this.stakingProcessActive = false;

        this.showErrorTip(
          `${e.code ? "(Code: " + e.code + ") " : ""}${e.message || ""}`,
          5000
        );
      }
    },

    async stakeWeld() {
      const validationResult = await this.v$.$validate();

      if (!validationResult) {
        console.log("validationResult", validationResult);
        return false;
      }

      if (
        parseFloat(this.weldStakeAmount) <
        parseFloat(this.minimumNeedleWeldAmount)
      ) {
        Object.assign(this.vuelidateExternalResults, {
          weldStakeAmount: [
            this.$t("stake_at_least", {
              weldAmount: this.minimumNeedleWeldAmount,
            }),
          ],
        });

        throw new Error(
          this.$t("stake_at_least", {
            weldAmount: this.minimumNeedleWeldAmount,
          })
        );
      }

      let canStakeAmount = await new Web3Service().canStakeAmount(
        this.weldStakeAmount,
        this.chainId
      );

      if (canStakeAmount !== true && canStakeAmount !== false) {
        this.canDepositMinimumAmount = canStakeAmount;
        return false;
      }

      if (canStakeAmount !== true) {
        return false;
      }

      await new Web3Service().depositWeld(
        this.selectedAccount,
        this.weldStakeAmount,
        this.chainId,
        {
          amountUSDT: this.getWeldAmountInUSDT(this.weldStakeAmount),
          rateUSDT: this.ratesUSDT.weld,
        }
      );

      await this.updateWeldBalance();
    },

    async buyWeldAndStake() {
      try {
        this.buyAndStakeProcessActive = true;

        let isBuySuccess = await this.buyWeld();

        if (!isBuySuccess) {
          this.buyAndStakeProcessActive = false;
          return;
        }

        this.weldStakeAmount = this.weldBalance;
        await this.stakeWeld();
        this.buyAndStakeProcessActive = false;
        this.$emit("stakingSuccess", {
          stakedAmount: this.weldStakeAmount,
        });
      } catch (e) {
        this.showErrorTip(
          `${e.code ? "(Code: " + e.code + ") " : ""}${e.message || ""}`,
          5000
        );
        this.buyAndStakeProcessActive = false;
      }
    },

    setMinimumWeldForStake() {
      this.weldStakeAmount = this.minimumNeedleWeldAmount;
    },

    setAllAvailableWeldForStake() {
      this.weldStakeAmount = this.weldBalance;
    },

    async getCoinAmountInUSDT(coin) {
      let coinRate = await PancakeSwapService.getCoinRate(coin);

      return Big(
        Big(this.coinAmount).times(Big(coinRate)).toPrecision(18, 1)
      ).toPrecision();
    },

    getWeldAmountInUSDT(weldAmount) {
      return Big(
        Big(weldAmount).times(Big(this.ratesUSDT.weld)).toPrecision(18, 1)
      ).toPrecision();
    },

    async validateAbilityForStakingMinAmount() {
      let canUserStake = await new Web3Service().canUserStake(
        this.selectedAccount,
        this.chainId
      );

      if (!canUserStake) {
        console.log("user can't stake");
        return false;
      }

      let canStakeMinimumNeedleAmount = await new Web3Service().canStakeAmount(
        this.minimumNeedleWeldAmount,
        this.chainId
      );

      if (canStakeMinimumNeedleAmount !== true) {
        return false;
      }

      return true;
    },
    async updateSelectedCoinBalance() {
      this.coinBalance = await new Web3Service().getCoinBalance(
        this.selectedAccount,
        this.selectedCoin,
        this.chainId
      );
    },
    closePopup() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(51, 63, 72, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .staking-popup__full {
    padding-bottom: 20px;
    padding-left: 0;
    border-left: 0;
  }

  &__actions {
    margin-top: 40px;
  }
}

.staking-popup {
  width: 650px;
  max-width: 100%;

  &__section {
    margin-bottom: 76px;

    &-header {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: pxToRem(24);
      line-height: pxToRem(30);
      font-weight: bold;
    }

    &-edit {
      color: $mainHot;
      font-weight: 700;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-body {
      position: relative;
    }
  }

  &__amount {
    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;

        &-title {
          margin-right: 10px;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          color: $gray1;
        }

        &-value {
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          color: $mainBlack;
          font-weight: 700;
        }
      }
    }
  }

  &__buy {
    position: relative;
    margin-top: 30px;

    &-hint {
      text-align: center;
      color: $mainHot;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      margin-bottom: 30px;
    }
  }

  &__stake {
    position: relative;
  }
}

.weld-buy-form,
.weld-stake-form {
  position: relative;

  &__input {
    display: flex;
    align-items: flex-end;
    margin-bottom: 55px;

    &-el {
      margin-right: 30px;
    }
  }

  &__header {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__coin-balance {
    color: $gray1;
  }

  &__setters {
    display: flex;
    align-items: center;

    &-item {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
      background-color: $grayLight;
      padding: 5px;
      border-radius: 6px;
      cursor: pointer;
      transition: opacity 0.2s linear;
      margin: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__actions {
    .button-primary {
      width: auto;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &-stake {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-hint {
        margin-top: 10px;
        color: $gray1;
        font-size: pxToRem(14);
        line-height: pxToRem(18);
      }
    }
  }
}
</style>
