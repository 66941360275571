import axios from "axios";
import { Web3Service } from "@/services/crypto/web3/web3-service";

class _PancakeSwapService {
  static get API_URL() {
    return "https://api.pancakeswap.info/api/v2";
  }

  async getWeldRate() {
    let apiResponse = await axios.get(
      `${PancakeSwapService.API_URL}/tokens/${
        Web3Service.WELD_ADDRESS[Web3Service.CHAINS.BSC_MAIN]
      }`
    );

    return parseFloat(apiResponse.data.data.price).toFixed(18);
  }

  async getWeldBNBRate() {
    let apiResponse = await axios.get(
      `${PancakeSwapService.API_URL}/tokens/${
        Web3Service.WELD_ADDRESS[Web3Service.CHAINS.BSC_MAIN]
      }`
    );

    return parseFloat(apiResponse.data.data.price_BNB).toFixed(18);
  }

  async getCoinRate(coin) {
    let apiResponse = await axios.get(
      `${PancakeSwapService.API_URL}/tokens/${
        Web3Service.COINS[Web3Service.CHAINS.BSC_MAIN][coin.toUpperCase()]
          .address
      }`
    );

    return parseFloat(apiResponse.data.data.price).toFixed(18);
  }

  async getWeldAndCoinRate(coin) {
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.get(
          `${PancakeSwapService.API_URL}/tokens/${
            Web3Service.WELD_ADDRESS[Web3Service.CHAINS.BSC_MAIN]
          }`
        ),
        axios.get(
          `${PancakeSwapService.API_URL}/tokens/${
            Web3Service.COINS[Web3Service.CHAINS.BSC_MAIN][coin.toUpperCase()]
              .address
          }`
        ),
      ])
        .then(([{ data: weldRate }, { data: coinRate }]) => {
          resolve({
            weldRate: parseFloat(weldRate.data.price).toFixed(18),
            coinRate: parseFloat(coinRate.data.price).toFixed(18),
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
export const PancakeSwapService = new _PancakeSwapService();
