<template>
  <div class="web3-install-guide">
    <div class="web3-install-guide__title">
      For using this page Metamask Extension required
    </div>
    <div class="web3-install-guide__image">
      <img :src="`${baseURL}img/metamask.png`" alt="" />
    </div>
    <div
      class="web3-install-guide__link"
      v-if="metamaskExtensionUrls[currentBrowser]"
    >
      <a :href="metamaskExtensionUrls[currentBrowser]" target="_blank"
        >Install Metamask Extension</a
      >
    </div>
    <div class="web3-install-guide__not-supported" v-else>
      Current browser not supported
    </div>
  </div>
</template>

<script>
import { detect } from "detect-browser";
import { Web3Service } from "@/services/crypto/web3/web3-service";
export default {
  name: "MetamaskInstallGuide",
  data() {
    return {
      currentBrowser: "chrome",
      baseURL: process.env.BASE_URL,
      checkMetamaskInterval: null,
      metamaskExtensionUrls: {
        chrome:
          "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ru",
        firefox: "https://addons.mozilla.org/ru/firefox/addon/ether-metamask/",
        edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US",
      },
    };
  },
  created() {
    this.currentBrowser = detect().name;

    this.checkMetamaskInterval = setInterval(async () => {
      if (window.ethereum) {
        try {
          await new Web3Service().loadWeb3ToStore();
          clearInterval(this.checkMetamaskInterval);
          // eslint-disable-next-line no-empty
        } catch {}
      }
    }, 3000);
  },
  beforeDestroy() {
    if (this.checkMetamaskInterval) {
      clearInterval(this.checkMetamaskInterval);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.web3-install-guide {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
    font-size: pxToRem(28);
    line-height: pxToRem(32);
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__image {
    max-width: 100%;
    width: 320px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }

  &__link {
    a {
      text-decoration: none;
      color: $mainCold;
      font-size: pxToRem(20);
      line-height: pxToRem(24);
    }
  }

  &__not-supported {
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainHot;
  }
}
</style>
