<template>
  <div class="wrapper">
    <story
      class="page__story"
      ref="storyBlock"
      v-if="userData.type !== 'mother' && 0"
    ></story>
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--crypto" v-if="web3Exists">
        <div class="crypto__header">
          <div class="crypto__header-info">
            <div class="crypto__header-account">
              <div class="crypto__header-account-title">
                {{ $t("connected_account") }}:
              </div>
              <div
                class="crypto__header-account-select"
                v-if="this.selectedAccount"
              >
                <div class="crypto__header-account-selected">
                  {{ this.selectedAccount }}
                </div>
              </div>
              <div class="crypto__header-account-connect" v-else>
                <primary-button
                  :label="$t('connect_account')"
                  @click="connectAccounts"
                ></primary-button>
              </div>
            </div>
            <div class="crypto__header-balance">
              <div class="crypto__header-balance-title">
                {{ $t("balance") }} <b>(WELD)</b>:
              </div>
              <div class="crypto__header-balance-value">
                {{ toFixedDecimals(selectedAccountWeldBalance, 2) }}
              </div>
              <div
                class="crypto__header-balance-update"
                @click="updateWeldBalance"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.224 3.01983C12.3484 2.26896 11.2967 1.74263 10.16 1.49448C8.7038 1.17658 7.18416 1.33179 5.82231 1.93749C4.46045 2.5432 3.32749 3.56779 2.58835 4.86209C1.84922 6.15639 1.54251 7.65282 1.71288 9.13353C1.88325 10.6142 2.5218 12.0019 3.53556 13.0945C4.54933 14.1871 5.88538 14.9277 7.34921 15.2082C8.81304 15.4888 10.3282 15.2949 11.6742 14.6546C12.7948 14.1214 13.7495 13.302 14.4451 12.2849C14.6775 11.9451 14.5384 11.4903 14.1758 11.2953C13.8131 11.1003 13.365 11.24 13.1229 11.573C12.5845 12.3135 11.8675 12.9116 11.0337 13.3082C9.9744 13.8121 8.78193 13.9648 7.62989 13.744C6.47784 13.5232 5.42635 12.9404 4.6285 12.0805C3.83066 11.2206 3.32811 10.1285 3.19403 8.96312C3.05995 7.79778 3.30134 6.62007 3.88304 5.60144C4.46475 4.58282 5.3564 3.77645 6.4282 3.29976C7.49999 2.82306 8.69596 2.70091 9.84199 2.9511C10.6571 3.12905 11.4167 3.48875 12.0665 3.99829L10.6462 4.04257C10.2782 4.05405 9.9892 4.36168 10.0007 4.72969C10.0121 5.09771 10.3198 5.38673 10.6878 5.37526L13.6281 5.28358C14.1801 5.26637 14.6136 4.80492 14.5964 4.2529L14.5047 1.31264C14.4932 0.944633 14.1856 0.655603 13.8176 0.667078C13.4496 0.678553 13.1606 0.986187 13.172 1.3542L13.224 3.01983Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="crypto__stakes">
          <div class="staking__wallets">
            <custom-select
              :empty-placeholder="$t('select_available_staking_account')"
              v-model="selectedWalletWithStaking"
              :values="formattedWalletsWithStaking"
              :show-selected-title="true"
            ></custom-select>
          </div>
          <div class="staking__table">
            <div class="staking__table-header">
              <div class="staking__table-col">{{ $t("staked_amount") }}</div>
              <div class="staking__table-col staking__table-col--centered">
                {{ $t("staking_rate") }}
              </div>
              <div class="staking__table-col">{{ $t("weekly_gain") }}</div>
              <div class="staking__table-col">{{ $t("accumulated_gain") }}</div>
            </div>
            <div
              class="staking__table-body"
              v-if="currentStakes && currentStakes.length > 0"
            >
              <div
                class="staking__table-row staking"
                v-for="(stakingItem, index) in currentStakes"
                :key="`staking-${index}`"
              >
                <div class="staking__table-col">
                  <div class="staking__amount">
                    <div class="staking__amount-value">
                      {{ toFixedDecimals(stakingItem.amount, 2) }}
                    </div>
                    <div class="staking__amount-coin">WELD</div>
                  </div>
                </div>
                <div class="staking__table-col">
                  <div class="staking__rate">{{ stakingRate }}%</div>
                </div>
                <div class="staking__table-col">
                  <div class="staking__gain">
                    +
                    {{ toFixedDecimals(calculateWeeklyGain(stakingItem), 2) }}
                    WELD
                  </div>
                </div>
                <div class="staking__table-col">
                  <div class="staking__gain">
                    +
                    {{
                      toFixedDecimals(calculateAccumulatedGain(stakingItem), 2)
                    }}
                    WELD
                  </div>
                </div>
              </div>

              <div class="staking__table-row staking__table-row--action">
                <div class="crypto__stakes-button">
                  <primary-button
                    @click="showStakingPopup"
                    :label="$t('stake_weld')"
                  ></primary-button>
                  <div class="crypto__stakes-button-hint">
                    {{ $t("stake_term") }}
                  </div>
                </div>
              </div>

              <div class="staking__table-row staking__table-row--action">
                <div class="crypto__stakes-withdraw">
                  <primary-button
                    :disabled="!canUserWithdraw"
                    @click="withdrawWeld"
                    :label="`${
                      canUserWithdraw
                        ? $t('withdraw') +
                          toFixedDecimals(availableWithdrawAmount, 2) +
                          ` WELD`
                        : $t('withdraw') + ` WELD`
                    }`"
                  ></primary-button>
                  <div
                    class="crypto__stakes-withdraw-date"
                    v-if="!canUserWithdraw && nearestWithdrawDate.length > 0"
                  >
                    {{
                      $t("nearest_withdraw_date", {
                        withdrawDate: nearestWithdrawDate,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="staking__table-body" v-else>
              <div class="staking__table-empty">
                {{ $t("didnt_stake_weld_hint", { coinAmount: "1000 USDT" }) }}
              </div>
              <div class="staking__table-row staking__table-row--action">
                <div class="crypto__stakes-button">
                  <primary-button
                    v-if="canUserStake && this.selectedAccount"
                    @click="showStakingPopup"
                    :label="$t('stake_weld')"
                  ></primary-button>
                  <div class="crypto__stakes-button-hint">
                    {{ $t("stake_term") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transaction-history-block
            :transactions="selectedWalletWithStakingTransactions"
            class="account-transactions"
          ></transaction-history-block>
        </div>
        <staking-popup
          v-if="isShowStakingPopup"
          @closed="closeStakingPopup"
          @stakingSuccess="processSuccessStaking"
          :chain-id="chainId"
          :selected-account="selectedAccount"
          :weld-balance="selectedAccountWeldBalance"
        ></staking-popup>
        <base-loader v-if="initialDataLoading"></base-loader>
      </div>
      <div class="page page--crypto" v-else>
        <metamask-install-guide></metamask-install-guide>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Web3Service } from "@/services/crypto/web3/web3-service";
import PrimaryButton from "@/components/button/PrimaryButton";
import StakingPopup from "@/components/profile/Token/StakingPopup";
import { CryptoApiHelper } from "@/office/crypto-api";
import { MathExtendedHelper } from "@/services/math/math-extended";
import BaseLoader from "@/components/base/BaseLoader";
import CustomSelect from "@/components/input/CustomSelect";
import TransactionHistoryBlock from "@/components/profile/Token/TransactionHistoryBlock";
import MetamaskInstallGuide from "@/components/profile/Token/MetamaskInstallGuide";
import mathMixin from "@/mixins/math-mixin";
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import Story from "@/components/profile/Tasks/Story";
import { TasksService } from "@/services/tasks/tasks.service";
import { EventsService } from "@/services/events/events.service";

export default {
  name: "Token",
  components: {
    Story,
    MainLayoutHeader,
    MetamaskInstallGuide,
    TransactionHistoryBlock,
    CustomSelect,
    BaseLoader,
    StakingPopup,
    PrimaryButton,
  },
  mixins: [mathMixin],
  inject: ["showSuccessTip"],
  metaInfo: {
    title: "Staking",
  },
  provide() {
    return {
      updateWeldBalance: this.updateWeldBalance,
      validateNetworkAndChangeIfNotValid:
        this.validateNetworkAndChangeIfNotValid,
      connectAccounts: this.connectAccounts,
    };
  },
  data() {
    return {
      availableCoins: Web3Service.AVAILABLE_COINS,
      selectedAccount: null,
      selectedAccountWeldBalance: 0,
      isChainValid: false,
      isShowStakingPopup: false,
      currentStakes: [],
      stakingRate: 12,
      initialDataLoading: true,
      selectedWalletWithStaking: null,
      walletsWithStaking: [],
      selectedWalletWithStakingTransactions: [],
      web3Exists: true,
      canUserStake: false,
      canUserWithdraw: false,
      availableWithdrawAmount: 0,
      nearestWithdrawDate: "",
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapGetters(["web3Instance", "userData"]),
    ...mapState({
      accounts: (state) => state.crypto.accounts,
      chainId: (state) => state.crypto.currentChainId,
    }),
    formattedWalletsWithStaking: function () {
      return this.walletsWithStaking.map((_w) => ({
        id: _w.address,
        title: _w.address,
      }));
    },
  },
  async created() {
    this.initialDataLoading = true;

    if (!this.web3Instance) {
      await new Web3Service().loadWeb3ToStore();
    }

    if (!this.web3Instance) {
      this.web3Exists = false;
    } else {
      this.selectedAccount = this.accounts[0] || null;

      if (this.selectedAccount) {
        // await this.connectAccounts();
        await this.validateNetworkAndChangeIfNotValid();
        await this.validateCanUserStake();
      }

      if (this.$route.query.start_staking === "true") {
        if (!this.selectedAccount) {
          await this.connectAccounts();
        }

        this.showStakingPopup();
      }

      await this.loadStakingWallets();
    }

    this.initialDataLoading = false;
  },
  watch: {
    accounts: function () {
      if (this.accounts.length === 0) {
        this.selectedAccount = null;
        return;
      }

      if (!this.selectedAccount) {
        this.selectedAccount = this.accounts[0] || null;
        return;
      }

      if (
        !this.accounts.findIndex((acc) => acc === this.selectedAccount) > -1
      ) {
        this.selectedAccount = this.accounts[0];
      }
    },
    selectedAccount: async function () {
      this.initialDataLoading = true;
      try {
        await this.updateWeldBalance();
        await this.validateNetworkAndChangeIfNotValid();
        await this.validateCanUserStake();
        await this.loadStakingWallets();
      } catch (e) {
        console.log(e);
      } finally {
        this.initialDataLoading = false;
      }
    },
    chainId: async function () {
      this.initialDataLoading = true;
      console.log("chain changed");
      await this.validateNetworkAndChangeIfNotValid();
      await this.updateWeldBalance();
      this.initialDataLoading = false;
    },
    selectedWalletWithStaking: async function () {
      this.initialDataLoading = true;
      await this.loadStakesBySelectedStakingAccount();
      await this.loadAccountTransactions();
      this.initialDataLoading = false;
    },
    currentStakes: async function () {
      if (this.currentStakes.length === 0) {
        this.canUserWithdraw = false;
        this.availableWithdrawAmount = 0;
        this.nearestWithdrawDate = "";
        return;
      }

      let finishedStake = this.currentStakes.find(
        (_stakeItem) =>
          this.$moment().diff(this.$moment(_stakeItem.date), "days") >= 365
      );

      if (!finishedStake) {
        this.canUserWithdraw = false;
        this.availableWithdrawAmount = 0;
        this.nearestWithdrawDate = this.$moment(this.currentStakes[0].date)
          .add(1, "y")
          .format("YYYY-MM-DD hh:mm:ss");

        return;
      }

      this.canUserWithdraw = true;
      this.availableWithdrawAmount =
        await new Web3Service().getAvailableWithdrawAmount(
          this.selectedAccount,
          this.chainId
        );
      this.nearestWithdrawDate = "";
    },
  },
  methods: {
    async connectAccounts() {
      await new Web3Service()
        .requestAccounts()
        .catch((err) => console.log(err));
    },
    async withdrawWeld() {
      this.initialDataLoading = true;
      await new Web3Service().withdrawStakes(
        this.selectedAccount,
        this.chainId
      );
      this.showSuccessTip(
        `You successfully withdraw ${this.availableWithdrawAmount} WELD!`,
        5000
      );
      this.initialDataLoading = false;
    },
    async updateWeldBalance() {
      if (this.selectedAccount) {
        this.selectedAccountWeldBalance =
          await new Web3Service().getWeldBalance(
            this.selectedAccount,
            this.chainId
          );
      } else {
        this.selectedAccountWeldBalance = 0;
      }
    },
    async validateCanUserStake() {
      this.canUserStake = await new Web3Service().canUserStake(
        this.selectedAccount,
        this.chainId
      );
    },
    async validateNetworkAndChangeIfNotValid() {
      this.isChainValid =
        await new Web3Service().validateNetworkAndChangeIfNotValid(
          this.chainId
        );
    },
    async loadStakingWallets() {
      let sWalletsResponse = await CryptoApiHelper.getWalletsWithStaking();
      this.walletsWithStaking = sWalletsResponse.data.wallets;

      if (
        this.selectedAccount &&
        !this.walletsWithStaking.find(
          (_w) => _w.address === this.selectedAccount
        )
      ) {
        this.walletsWithStaking.unshift({
          address: this.selectedAccount,
        });
      }

      if (this.selectedWalletWithStaking) {
        if (
          this.walletsWithStaking.findIndex(
            (_w) => _w.address === this.selectedWalletWithStaking
          ) < 0
        ) {
          this.selectedWalletWithStaking = this.walletsWithStaking[0]
            ? this.walletsWithStaking[0].address
            : null;
        }
      } else {
        this.selectedWalletWithStaking = this.walletsWithStaking[0]
          ? this.walletsWithStaking[0].address
          : null;
      }
    },
    async loadStakesBySelectedStakingAccount() {
      if (this.selectedWalletWithStaking === null) {
        return [];
      }

      this.currentStakes = await new Web3Service().getStakes(
        this.selectedWalletWithStaking,
        this.chainId
      );
    },
    async loadAccountTransactions() {
      if (this.selectedWalletWithStaking === null) {
        return [];
      }

      let _transactionsResponse = await CryptoApiHelper.getAccountTransactions(
        this.selectedWalletWithStaking
      );

      this.selectedWalletWithStakingTransactions =
        _transactionsResponse.data.transactions;

      this.updateTransactionsStatuses();
    },
    async updateTransactionsStatuses() {
      let _pendingTransactions =
        this.selectedWalletWithStakingTransactions.filter(
          (_transaction) => _transaction.status === "pending"
        );

      for (const _transaction of _pendingTransactions) {
        let transactionStatus = await new Web3Service().updateTransactionStatus(
          _transaction.transaction_hash,
          _transaction.type
        );

        let _transactionIndex =
          this.selectedWalletWithStakingTransactions.findIndex(
            (_t) => _t.transaction_hash === _transaction.transaction_hash
          );

        this.selectedWalletWithStakingTransactions.splice(
          _transactionIndex,
          1,
          {
            ...this.selectedWalletWithStakingTransactions[_transactionIndex],
            status: transactionStatus,
          }
        );
      }
    },
    calculateWeeklyGain(stakingItem) {
      let rate = this.stakingRate / 100;

      let daysDiff =
        this.$moment().diff(this.$moment(stakingItem.date), "days") + 1;

      return this.calculateGain(
        stakingItem.amount,
        daysDiff > 7 ? 7 : daysDiff,
        rate,
        365
      );
    },
    calculateAccumulatedGain(stakingItem) {
      console.log("stakingItem", stakingItem);
      let rate = this.stakingRate / 100;

      let daysDiff =
        this.$moment().diff(this.$moment(stakingItem.date), "days") + 1;

      return this.calculateGain(stakingItem.amount, daysDiff, rate, 365);
    },
    calculateGain(amount, daysDiff, rate, totalDays) {
      let dailyGain = (amount * rate) / totalDays;

      return MathExtendedHelper.toFixed(daysDiff * dailyGain, 2);
    },
    async processSuccessStaking(stakeData) {
      this.showSuccessTip(
        this.$t("tip.successfully_staked_weld", {
          weldAmount: stakeData.stakedAmount,
        }),
        5000
      );

      this.closeStakingPopup();

      await TasksService.completeTask(TasksService.TASK_STAKING);
      await EventsService.fireEvent(EventsService.EVENT_STAKING);
      this.$refs.storyBlock.updateStory();

      this.initialDataLoading = true;
      await this.loadStakesBySelectedStakingAccount();
      await this.loadAccountTransactions();
      this.initialDataLoading = false;
    },
    showStakingPopup() {
      this.isShowStakingPopup = true;
    },
    closeStakingPopup() {
      this.isShowStakingPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.buy-weld-button {
  margin-bottom: 25px;
  margin-top: 25px;
}

.page {
  &--crypto {
    padding-top: 40px;
  }
}

.crypto {
  &__header {
    display: flex;
    align-items: flex-start;

    &-info {
      margin-left: auto;
    }

    &-account {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      text-align: right;

      &-title {
        margin-right: 10px;
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $gray1;
      }

      &-selected {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $mainCold;
        font-weight: 600;
      }
    }

    &-balance {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-title {
        margin-right: 10px;
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $gray1;
      }

      &-value {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $mainCold;
        font-weight: 600;
      }

      &-update {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 4px;

        &:hover {
          svg {
            transform: rotate(360deg);
            opacity: 0.8;
          }
        }

        svg {
          transition: transform 0.2s linear, opacity 0.2s linear;
          width: 100%;
          height: 100%;

          path {
            fill: $mainCold;
          }
        }
      }
    }
  }

  &__stakes {
    margin-top: 50px;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 20px;

      .button-primary {
        width: auto;
      }

      &-hint {
        margin-top: 10px;
        color: $gray1;
        font-size: pxToRem(14);
        line-height: pxToRem(18);
      }
    }

    &-withdraw {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button-primary {
        width: auto;
      }

      &-date {
        margin-top: 10px;
        color: $gray1;
        font-size: pxToRem(14);
        line-height: pxToRem(18);
      }
    }
  }
}

.staking {
  &__wallets {
    padding: 55px 25px 35px;
    background: #ffffff;
    box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;
    margin-bottom: 50px;
  }

  &__table {
    background: #ffffff;
    box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;

    &-empty {
      padding: 38px 32px;
      text-align: center;
      color: $gray1;
    }

    &-header {
      display: flex;
      align-items: stretch;
    }

    &-header & {
      &-col {
        padding-top: 40px;
        padding-bottom: 36px;
        color: $gray1;
        font-weight: 500;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      transition: background-color 0.2s linear;
      border-top: 1px solid #dde5ed;

      &:not(&--action) {
        &:hover {
          background-color: $grayLight;
        }
      }
    }

    &-col {
      width: 25%;
      display: flex;
      align-items: center;
      padding: 20px 25px;

      &--centered {
        justify-content: center;
      }
    }
  }

  &__amount {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    &-value {
      color: $mainCold;
      margin-right: 5px;
      flex-shrink: 1;
      flex-grow: 0;
      text-overflow: clip;
      overflow: hidden;
    }

    &-coin {
      font-weight: bold;
    }
  }

  &__rate {
    color: $mainHot;
    text-align: center;
    width: 100%;
  }

  &__gain {
    color: $mainCold;
    font-weight: bold;
  }
}

.account-transactions {
  margin-top: 65px;
}
</style>
