<template>
  <div class="account-transactions">
    <div class="account-transactions__title">
      {{ $t("transactions_history") }}
    </div>
    <div class="account-transactions__table">
      <div class="account-transactions__table-header">
        <div class="account-transactions__table-col">{{ $t("type") }}</div>
        <div class="account-transactions__table-col">{{ $t("hash") }}</div>
        <div class="account-transactions__table-col">{{ $t("time") }}</div>
        <div class="account-transactions__table-col">
          {{ $t("amount") }} (WELD)
        </div>
        <div class="account-transactions__table-col">
          {{ $t("amount") }} ({{ $t("equal_coin") }})
        </div>
      </div>
      <div
        class="account-transactions__table-body"
        v-if="transactions && transactions.length > 0"
      >
        <div
          class="account-transactions__table-row account-transaction"
          v-for="transaction in transactions"
          :key="`transaction-${transaction.id}`"
        >
          <div class="account-transactions__table-col">
            <div class="account-transaction__type">
              <div class="account-transaction__type-value">
                {{ getTransactionTypeTitle(transaction.type) }}
              </div>
              <div
                class="account-transaction__type-status"
                :class="`account-transaction__type-status--${transaction.status}`"
              >
                ({{ transaction.status }})
              </div>
            </div>
          </div>
          <div class="account-transactions__table-col">
            <div class="account-transaction__hash">
              <div class="account-transaction__hash-value">
                {{ transaction.transaction_hash }}
              </div>
              <div
                class="account-transaction__hash-copy"
                @click="copyTxHashToClipboard(transaction.transaction_hash)"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.89477 13.2C4.84832 13.2 4 12.1109 4 11.0876V2.85283C4 1.82954 4.84832 1 5.89477 1H12.1055C13.1519 1 14.1054 1.82954 14.1054 2.85283V11.0876C14.1054 12.1109 13.2571 13.2 12.2107 13.2H9H5.89477ZM5.10547 11.0876V3.16455C5.10547 2.59606 5.46161 2.15625 6.04297 2.15625H9.10547H11.9805C12.5618 2.15625 12.9961 2.59606 12.9961 3.16455V7V11.0876C12.9961 11.6561 12.5618 12.1177 11.9805 12.1177H6.04297C5.46161 12.1177 5.10547 11.6561 5.10547 11.0876Z"
                    fill="#333F48"
                  />
                  <path
                    d="M3.1406 12.8281C3.1406 13.4347 3.66963 14.2 4.29685 14.2H7.99997H11C11 14.2 11.3984 14.2 11.5 14.5V15C11.5 15 11.421 15.3521 11 15.3521H7.49997H4.06247C2.93347 15.3521 2.00001 14.2637 2.00001 13.1719V4C1.9995 4 2.02963 3.32227 2.45069 3.32227C2.59273 3.32227 2.66731 3.32228 2.70377 3.32229C2.70678 3.32225 2.70977 3.32226 2.71274 3.3223C2.71005 3.3223 2.70707 3.32229 2.70377 3.32229C2.70251 3.32231 2.70124 3.32233 2.69997 3.32236C2.69997 3.32236 2.76863 3.32233 2.71274 3.3223C2.70977 3.32226 2.70678 3.32225 2.70377 3.32229C2.70707 3.32229 2.71005 3.3223 2.71274 3.3223C3.0327 3.32674 3.14094 3.79172 3.1406 4V8.35205V12.8281Z"
                    fill="#333F48"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="account-transactions__table-col">
            <div class="account-transaction__date">
              {{ transaction.created_at }}
            </div>
          </div>
          <div class="account-transactions__table-col">
            <div class="account-transaction__amount-weld">
              {{ toFixedDecimals(transaction.sm_weld, 2) }}
            </div>
          </div>
          <div class="account-transactions__table-col">
            <div
              class="account-transaction__amount-coin"
              v-if="transaction.type === transactionTypes.BUY"
            >
              <div class="account-transaction__amount-coin-amount">
                {{ toFixedDecimals(transaction.sm_coin, 2) }}
              </div>
              <div class="account-transaction__amount-coin-title">
                {{ transaction.coin }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="account-transactions__table-body" v-else>
        <div class="account-transactions__table-empty">
          {{ $t("no_transactions_yet") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CRYPTO_API_TRANSACTION_TYPES } from "@/office/crypto-api";
import Big from "big.js";
import mathMixin from "@/mixins/math-mixin";

export default {
  name: "TransactionHistoryBlock",
  mixins: [mathMixin],
  inject: ["showSuccessTip"],
  props: {
    transactions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      bigHelper: Big,
      transactionTypes: CRYPTO_API_TRANSACTION_TYPES,
    };
  },
  methods: {
    getTransactionTypeTitle(type) {
      switch (type) {
        case CRYPTO_API_TRANSACTION_TYPES.BUY:
          return this.$t("buy_type");
        case CRYPTO_API_TRANSACTION_TYPES.STAKING:
          return this.$t("staking_type");
      }

      return "";
    },
    copyTxHashToClipboard(txHash) {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(txHash).then(
        () => {
          this.showSuccessTip(`TxHash ${txHash} copied to clipboard.`);
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.account-transactions {
  &__title {
    font-size: pxToRem(28);
    line-height: pxToRem(32);
    margin-bottom: 35px;
  }

  &__table {
    background: #ffffff;
    box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;

    &-empty {
      padding: 38px 32px;
      text-align: center;
      color: $gray1;
    }

    &-header {
      display: flex;
      align-items: stretch;
    }

    &-header & {
      &-col {
        padding-top: 40px;
        padding-bottom: 36px;
        color: $gray1;
        font-weight: 500;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      transition: background-color 0.2s linear;
      border-top: 1px solid #dde5ed;

      &:not(&--action) {
        &:hover {
          background-color: $grayLight;
        }
      }
    }

    &-col {
      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      width: 20%;
      display: flex;
      align-items: center;
      padding: 20px 15px;

      &--centered {
        justify-content: center;
      }
    }
  }
}

.account-transaction {
  &__type {
    display: flex;
    align-items: flex-end;

    &-value {
      margin-right: 5px;
    }

    &-status {
      font-size: pxToRem(14);
      line-height: pxToRem(18);

      &--complete {
        color: $mainCold;
      }

      &--error {
        color: $mainHot;
      }

      &--pending {
        color: $mainYellow;
      }
    }
  }

  &__hash {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    &-value {
      flex-shrink: 1;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-copy {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: 10px;
      transition: opacity 0.2s linear;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__date {
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    color: $gray1;
  }

  &__amount-coin {
    display: flex;

    &-amount {
    }

    &-title {
      margin-left: 5px;
      font-weight: bold;
      color: $gray1;
    }
  }
}
</style>
