class MathExtended {
  toFixed(value, precision) {
    let power = Math.pow(10, precision || 0);
    return String(Math.round(value * power) / power);
  }

  truncateDecimals(number, digits) {
    let multiplier = Math.pow(10, digits),
      adjustedNum = number * multiplier,
      truncatedNum = Math[adjustedNum < 0 ? "ceil" : "floor"](adjustedNum);

    return truncatedNum / multiplier;
  }
}

export const MathExtendedHelper = new MathExtended();
